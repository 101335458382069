
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import FormBodyProduct from "@/components/occurrences/FormBodyProduct.vue";
import store from "@/store";
import FieldErrorMessage from "@/components/global/FieldErrorMessage.vue";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

const router = useRouter();
/* eslint-disable */
export default defineComponent({
  name: "Occurence",
  components: {
    FormBodyProduct,
    FieldErrorMessage,
  },
  props: {
    tipo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      widgetClasses: String,
      currInvoiceNumber: "",
      currInvoice: {},
      invoices: [],
      invoicesAPI: [],
      searchInput: "",
      motivation: "Outro",
      motivation_error: [],
      other_motivation: "",
      observations: "",
      collectPickedVal: false,
      checkedConditions: [],
      selectedProducts: [],
      numberOfDaysInvoices: 90,

      prodsFormFields: [
        {
          art: "",
          ref: "",
          brand: "",
          qtt: "1",
          doc: "",
          batch: "",
          price: "",
          observations: "",
          prod: {},
          errors: {},
        },
      ],

      fieldErrors: {},
    };
  },
  watch: {
    motivation() {
      this.motivation_error["motivation"] = "";
    },
    //when invoices quantity change we update available invoices
    invoicesQtt() {
      this.invoices = store.getters.getAvailabelOccInvoices;
    },

    currInvoiceNumber() {
      //set selected invoice
      this.currInvoice = this.invoices.filter(
        (p: any) => p.intNumero == this.currInvoiceNumber
      )[0];


      //reset field art name
      this.prodsFormFields = this.prodsFormFields.map((obj) => {
        return { ...obj, art: "" };
      });
    },
  },
  computed: {
    motivationArray() {
      if (this.tipo.toUpperCase() == "DEV") {
        return ["Desistência", "Produto não solicitado", "Validade", "Outro"];
      } else {
        return [
          {
            value: "Receção não conforme: Danos no transporte",
            text: "Danos no transporte",
          },
          {
            value: "Receção não conforme: Danos por mau acondicionamento",
            text: "Danos por mau acondicionamento",
          },
          {
            value: "Receção não conforme: Produto com defeito",
            text: "Produto com defeito",
          },
          {
            value: "Receção não conforme: Falta de instruções",
            text: "Falta de instruções",
          },
          {
            value: "Receção não conforme: Mercadoria a mais não faturada",
            text: "Mercadoria a mais não faturada",
          },
          {
            value: "Receção não conforme: Mercadoria faturada e não enviada",
            text: "Mercadoria faturada e não enviada",
          },
          {
            value: "Receção não conforme: Produto diferente do solicitado",
            text: "Produto diferente do solicitado",
          },
        ];
      }
    },

    //we need to know when invoices change,
    invoicesQtt() {
      if (!store.getters.getInvoices) return 0;
      return store.getters.getInvoices.length;
    },
  },
  methods: {

    /*
    * ----------------DATA MANIPULATION----------------
    */

    setFieldValues(field, prod) {
      //field.art = prod.strDescArtigo;
      field.art = prod.strDescArtigo;
      field.ref = prod.strCodArtigo;
      field.prod = prod;


    },
    updateSelectedProducts(newVal: never, oldVal: never) {
      if (this.selectedProducts.includes(oldVal)) {
        this.selectedProducts = this.selectedProducts.filter(
            (el) => el !== oldVal
        );
      }
      this.selectedProducts.push(newVal);
    },

    updateProd(field, prod) {
      let product = this.currInvoice["Mov_Venda_Lin"].filter((p) => p.strDescArtigo == prod)[0];
      if(!product) return;
      this.setFieldValues(field, product);

    },

    updateProdQtt(field, quant) {
      field.qtt = quant;
    },
    updateProdObservations(field, observations) {
      field.observations = observations;
    },

    AddProdFormBody: function (e) {
      this.prodsFormFields.push({
        art: "",
        ref: "",
        qtt: "1",
        doc: "",
        batch: "",
        price: "",
        brand: "",
        observations: "",
        prod: {},
        errors: {},
      });
      e.preventDefault();
    },
    RemoveProdFormBody: function (index, prodStamp) {
      if (this.prodsFormFields.length > 1) {
        this.prodsFormFields.splice(index, 1);

        //remove from selected products array
        if (prodStamp) {
          this.selectedProducts = this.selectedProducts.filter(
              (elem) => elem !== prodStamp
          );
        }
      }
    },

    createRequest() {
      //if reason is "Outro" concat motivation to be "outro: specification"
      /*
      if(this.motivation == 'Outro') {
        this.motivation = 'Outro: ' + this.other_motivation
      }
      */

      let body = {
        obranome: this.currInvoiceNumber + "",
        no: store.getters.currentUser.numcl,
        obstab2: this.observations,
        //IF tipo de ocurrencia for incidencia então a devolução n existe, se for devolução pode ser ferragens a recolher ou cliente a devolver
        /*
        collectmethod:
          this.tipo.toUpperCase() == "INC"
            ? null
            : this.collectPickedVal == false
            ? "FERRAGENS"
            : "CLIENTE",
        */
        lines: Array(),
      };

      this.prodsFormFields.forEach((item) => {
        //if product is selected
        if (item.art) {
          let prodLine = {
            refe: item.ref,
            qtt: parseInt(item.qtt),
            design: item.art,
            lobs: item.observations,
          };

          body.lines.push(prodLine);
        }
      });

      return body;
    },

    /*
    * ----------------API POST----------------
     */
    postInc(request) {
      ApiService.post("/incidencias", request).then(() => {
        Swal.fire({
          text: "Incidencia registada com sucesso",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
        this.$router.go(-1);
      }).catch(() => {
        Swal.fire({
          text: "Erro ao registar incidencia",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
      });
    },


    /*
    * ------------------API FETCHING------------------
    */
    loadInvoices() {
      //load invoices from last 90 days passing start date as queryparaemter
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - this.numberOfDaysInvoices);
      let dateFilter = startDate.toISOString().split("T")[0];

      let queryParameter = `?pageNumber=${1}&pageSize=${9999}&intCodEntidade=${store.getters.currentUser.numcl}&DtmInicial=${dateFilter}`;

      ApiService.get('vendas', queryParameter).then((res) => {
        const invoices = res.data.Data.filter((inv) => inv.strAbrevTpDoc == "CFACT");
        this.invoices = invoices;
        this.invoicesAPI = invoices;

      })
          .catch((err) => {
            console.log(err);
          })
    },

    /*
    * -------------------UTILS--------------------
    */

    queryForInvoices(event) {
      console.log(this.invoices)
      this.invoices = this.invoicesAPI.filter((p : any) =>
          (p.intNumero + "").includes(this.searchInput)
      );



      if (this.invoices.length == 0) {
        return;
      }

      let invoicesTemp = this.invoices.filter(
          (p: any) => p.intNumero + "" == this.searchInput
      );

      //set select document to be number typed
      if (invoicesTemp.length > 0) {
        this.currInvoice = invoicesTemp[0];
        this.currInvoiceNumber = this.currInvoice["intNumero"];
      }
    },

    onUploadFiles(event) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {};
    },

    submitOcc() {
      if (this.verifyForm())
        return

      let request = this.createRequest();
      this.postInc(request);
    },

    back() {
      this.$router.go(-1);
    },



    /*
    * -------------------VALIDATION--------------------
     */

    verifyForm() {
      let errors = false;
      //Terms and conditions selected?
      if (this.checkedConditions.length < 3) {
        this.fieldErrors["checked"] =
            "É necessário aceitar os termos e condições";
        errors = true;
      } else {
        this.fieldErrors["checked"] = "";
      }

      //Iterate each product body and check if quantity is selected
      this.prodsFormFields.forEach((item) => {
        if (item.ref) {
          if (item.qtt == "") {
            item.errors["quantity"] = "Quantidade";
            errors = true;
          } else {
            item.errors["quantity"] = "";
          }
        }
      });
      //Check if reason is "Outro" if so the field cand be empty
      /*
      if (this.motivation == "Outro" && this.other_motivation == "") {
        this.motivation_error["motivation"] = "Motivo deve ser especificado";
        errors = true;
      } else {
        this.motivation_error["motivation"] = "";
      }
      */

      return errors;
    },

    /*
    * -------------------STYLES-------------------
    */

  },

  mounted() {
    const pageTittle =  this.tipo == "dev" ? "Nova devolução" : "Nova incidência";
    setCurrentPageTitle(pageTittle);
    this.loadInvoices();

  },
  //verify roter params
  beforeRouteEnter(to, from, next) {
    //verify if params are dev(devolução) or inc(incidencia)
    if (to.params["tipo"] == "dev" || to.params["tipo"] == "inc") {
      next();
    } else {
      next("/404");
    }
  },
  beforeRouteUpdate(to, from, next) {
    //verify if params are dev(devolução) or inc(incidencia)
    if (to.params["tipo"] == "dev" || to.params["tipo"] == "inc") {
      next();
    } else {
      next("/404");
    }
  },
});
